import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import FeaturesRenderText from '@/components/features/featuresRenderText';
import { GetTextEnum } from '@/enums/businessEnum';
import { getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { cn, ScrollShadow } from '@nextui-org/react';
import { useMount } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * pc 的文章
 */
export default () => {
  const { t } = useTranslation();

  const [privacyAgreementList] = useState([
    {
      title: t('隐私条款'),
      keyCode: GetTextEnum.PRIVACY_CLAUSE_CODE,
    },
    {
      title: t('实名认证'),
      keyCode: GetTextEnum.SYSTEM_REAL_NAME,
    },
    {
      title: t('用户协议'),
      keyCode: GetTextEnum.SYSTEM_USER,
    },
  ]);

  const [urlState] = useUrlState<any>({});
  const { type } = urlState || {};

  //获取隐私协议和用户协议
  const {
    data: privacyAgreement,
    run: getPrivacyAgreement,
    loading,
  } = useReq(getPrivacyAgreementApi, {
    manual: true,
  });
  // 选中的
  const [selected, setSelected] = useState<any>(
    GetTextEnum.PRIVACY_CLAUSE_CODE,
  );
  useMount(() => {
    if (type) {
      setSelected(type);
    }
  });

  useEffect(() => {
    getPrivacyAgreement({
      type: selected,
    });
  }, [selected]);

  return (
    <div className="pt-3">
      <div className="flex space-x-2">
        <div className="w-[220px] h-[80vh] bg-backgroundAuxiliaryColor py-5 px-6 rounded-md ">
          <div>
            {privacyAgreementList?.map((item: any) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <div
                  onClick={() => setSelected(item.keyCode)}
                  className={cn(
                    ' rounded-sm px-2.5 py-2 text-sm font-mono  mb-3 cursor-pointer',
                    {
                      'bg-pcPrimary': selected === item.keyCode,
                      ' text-auxiliaryTextColor': selected !== item.keyCode,
                    },
                  )}
                >
                  {item?.title}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex-1 bg-backgroundAuxiliaryColor/50 rounded-md p-5 max-w-[55vw] overflow-auto">
          <ScrollShadow hideScrollBar className="max-h-[80vh]">
            <BaseLoadingDisplay
              loading={loading}
              list={loading ? [] : [privacyAgreement]}
            >
              <FeaturesRenderText data={privacyAgreement} />
            </BaseLoadingDisplay>
          </ScrollShadow>
        </div>
      </div>
    </div>
  );
};
